import React, { useState, useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Input from "../components/forms/Input";
import {
  addBlog,
  getBlogs,
  updateBlog,
  deleteBlog,
} from "../utils/databaseHelper";
import { FormData } from "../utils/intefaces";
import dummyImage from "../assets/images/no-image.jpg";
import Nav from "../components/nav/Nav";

const BlogsList: React.FC = () => {
  
  let defaultData = {
    image: null,
    id: "",
    title: "",
    body: "",
    postDate: "",
    postedBy: "admin",
  };
  const [formData, setFormData] = useState<FormData>({ ...defaultData });

  const [blogs, setBlogs] = useState<object[] | null>(null);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [formError, setFormError] = useState<object | null | any>({
    fileError: "",
    error: "",
  });
  const [message, setMessage] = useState<string | null>(null);

  const [loadingState, setLoadingState] = useState({
    posting: false,
    loading: false,
  });

  useEffect(() => {
    setLoadingState((prev) => ({ ...prev, loading: true }));
    if (blogs === null) getBlogs(setBlogs, setLoadingState);
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormError({
      fileError: "",
      error: "",
    });
    if (e.target.name === "image") {
      setFormData({ ...formData, image: null });
      if (!e.target.files) return;
      let fileSize = e.target.files[0].size;
      if (fileSize / 1024 / 1024 > 1) {
        setFormError({ ...formError, fileError: "File too larger" });
        return;
      }
      setFormData({ ...formData, image: e.target.files[0] });
    } else setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!isEditing) {
      if (formData.image !== "" || formData.image !== null) {
        //check file size
        setFormError({ ...formError, error: "Image filed is required" });
      }
      addBlog(formData, setLoadingState);
    } else {
      updateBlog(formData.id, formData, setLoadingState);
    }
  };

  return (
    <>
      <div className=" py-4 rounded-3 bg-white px-4 pb-5 mb-5">
        {message !== null ? (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            {message}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        ) : null}
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h5 className="text-muted">All stories</h5>
            {loadingState.loading ? (
              <div className="d-flex align-items-center py-3">
                <div
                  className="spinner-grow text-secondary me-3 "
                  role="status"
                ></div>
                <div className="small text-muted">Loading! Please wait...</div>
              </div>
            ) : (
              <div className="me-3">
                {blogs?.map((blog: object | any) => (
                  <div key={blog.id} className=" border-bottom py-3">
                    <div className="d-flex align-items-start">
                      <div
                        style={{
                          background: `url(${
                            blog.image || dummyImage
                          }) no-repeat scroll center / cover`,
                          height: "100px",
                          width: "100px",
                        }}
                        className="me-3"
                      />
                      <div style={{ flex: "1" }}>
                        <h5>{blog.title}</h5>
                        <div>
                          {" "}
                          {blog.body
                            .substring(0, 100)
                            .replace(/<\/?[^>]+(>|$)/g, "")}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      {" "}
                      <button
                        className="btn btn-sm btn-secondary me-3"
                        onClick={() => {
                          setIsEditing(true);
                          setFormData(blog);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => {
                          deleteBlog(blog.id, setMessage);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="px-3">
              <h5 className="mb-3 text-muted">
                {!isEditing ? "Create new blog" : "Edit blog "}
              </h5>

              {loadingState.posting ? (
                <div className="d-flex align-items-center py-3">
                  <div
                    className="spinner-grow text-secondary me-3 "
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <div className="small text-muted">
                    Posting! Please wait...
                  </div>
                </div>
              ) : null}
              <form onSubmit={(e) => onSubmit(e)}>
                <label className="form-label">Upload image</label>
                <input
                  className="form-control mb-2"
                  id="image"
                  name="image"
                  type="file"
                  accept="image/*"
                  onChange={onChange}
                  disabled={loadingState.posting}
                />
                <div className="small text-muted mb-4 d-flex justify-content-between">
                  <span>Maximum upload size 1 MB</span>
                  {formError?.fileError !== "" && (
                    <span className="text-danger">
                      File larger than maximum allowed size
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <Input
                    id="title"
                    type="text"
                    placeHolder="Enter Title"
                    value={formData.title}
                    name="title"
                    onChange={onChange}
                    label="title"
                    disabled={loadingState.posting}
                    required={true}
                  />
                </div>
                <label className="form-label">Body</label>
                <ReactQuill
                  value={formData.body}
                  onChange={(value) =>
                    setFormData((prev) => ({ ...prev, body: value }))
                  }
                />
                <div className="mt-4 d-flex align-items-center">
                  <button
                    className="btn btn-primary me-3 "
                    disabled={loadingState.posting}
                  >
                    {isEditing ? "Update blog" : "Post new blog"}
                  </button>
                  <button
                    className=" btn btn-text-danger"
                    disabled={loadingState.posting}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsEditing(false);
                      setFormData(defaultData);
                    }}
                  >
                    Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogsList;
