export function convertToDateSting(date: string) {
  if (date) {
    let dateString = date
      .split(" ")[0]
      .replace(/[/]/g, "-")
      .split("-")
      .reverse()
      .join("-");
    return dateString;
  }
  return "";
}

export function formatCurrency(currency: number) {
  let currencyString = Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "ugx",
  });

  return currencyString.format(currency);
}

export function stringToTimestamp(dateString: string) {
  let [datePart, timePart] = dateString.split(" ");

  // Split the date components
  let [day, month, year] = datePart.split("/").map(Number);

  // Split the time components and handle AM/PM
  let [time, period] = timePart.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (period) {
    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    } else if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }
  }

  // Create the date object
  let date = new Date(year, month - 1, day, hours, minutes);

  // Convert to timestamp
  let timestamp = date.getTime();

  return timestamp;
}
