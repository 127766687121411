import { useContext, useEffect } from "react";
import { UserContext } from "../utils/context";
import { Navigate } from "react-router-dom";
import { auth } from "../utils/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { getCurrentUserInfo } from "../utils/AuthHandler";
const AuthRoutes = ({ children }: any) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  useEffect(() => {
    if(currentUser === undefined)
     getAuthUser();
  }, []);

  const getAuthUser = async () => {
  onAuthStateChanged(auth, async (user) => {
      if (user) {
        getCurrentUserInfo(user.uid, setCurrentUser);
        return;
      } else {
        setCurrentUser(null);
        return;
      }
    });
  };

  if (currentUser && currentUser.accountType === "admin") {
    return <Navigate to="/" />;
  } else{
    return children;
  }
   
};

export default AuthRoutes;
