import React, { useState, useContext } from "react";
import logo from "../assets/logo/teddy-logo.png";
import Input from "../components/forms/Input";
import { loginWithEmailAndPassword } from "../utils/AuthHandler";
import { UserContext } from "../utils/context";

const AuthLayout: React.FC = () => {
  const regx = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/);
  const errorTemplate = { emailError: "", credentialsError: "" };
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  const [loggingInState, setLoggingInState] = useState(false);
  const [formError, setFormError] = useState({
    ...errorTemplate,
  });

  const { currentUser, setCurrentUser } = useContext(UserContext);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFormError({ ...errorTemplate });
    setUserCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoggingInState(true);
    if (regx.test(userCredentials.email)) {
      loginWithEmailAndPassword(userCredentials, setFormError, setCurrentUser, setLoggingInState);
    } else {
      setFormError((prev) => ({
        ...prev,
        emailError: "Please enter a proper email address",
      }));
      setLoggingInState(false);
    }
  }
  return (
    <div className="d-flex align-items-center justify-content-center flex-column vh-100">
      {loggingInState && <div className="spinner-border text-success mb-2" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}
      
      <div className="auth_form_container shadow-sm rounded-3 d-flex align-items-center justify-content-center flex-column bg-white p-5">
        <img src={logo} width="80" />

        <form onSubmit={handleSubmit} className="w-100">
          <div className="mb-3">
            <Input
              type="text"
              label="Email address"
              placeHolder="Enter email address"
              name="email"
              value={userCredentials.email}
              onChange={handleChange}
              disabled={loggingInState}
              required={true}
            />
            {formError.emailError && (
              <div className="text-danger small mt-1">
                <span className="small">{formError.emailError}</span>
              </div>
            )}
          </div>

          <div className="mb-3">
            <Input
              type="password"
              label="password"
              name="password"
              placeHolder="************"
              value={userCredentials.password}
              onChange={handleChange}
              disabled={loggingInState}
              required={true}
            />
          </div>
          <button type="submit" className="btn btn-primary btn-sm mt-4 w-100">
            Login
          </button>
        </form>
      </div>
      {formError.credentialsError && (
        <div className="alert alert-danger w-50 text-center text-danger small mt-3">
          {formError.credentialsError}
        </div>
      )}
    </div>
  );
};

export default AuthLayout;
