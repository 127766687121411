import React from 'react'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const CircularSekeleton = () => {
  return (
    <div className="d-flex align-items-center bg-white p-2 rounded-3 gap-2">
        <Skeleton circle height={40} width={40} />
        <Skeleton count={3} height={5} width="100px" />
        </div>
  )
}

export default CircularSekeleton  