import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import NotFound from "./pages/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UserContext,
  OrdersContext,
  GroceriesContext,
  CustomersContext,
  BlogsContext,
  CategoriesContext,
} from "./utils/context";
import AuthLayout from "./layouts/AuthLayout";
import AdminRoutes from "./protectedRoutes/AdminRoutes";
import AuthRoutes from "./protectedRoutes/AuthRoutes";

export type UserContextType = {
  currentUser: object | null | "";
  setCurrentUser: React.Dispatch<React.SetStateAction<object>>;
};
export type OrderContextType = {
  orders: null;
  setOrders: React.Dispatch<React.SetStateAction<object>>;
};

export type customerType ={
  [key:string] : any,
  accountType: string,
  address:string,
  counttyName: string,
}

export type CustomersContextType = {
  customers: customerType | undefined;
  setCustomers: React.Dispatch<React.SetStateAction<object>>;
};
export type GroceriesContextType = {
  groceries: null;
  setGroceries: React.Dispatch<React.SetStateAction<object>>;
};


const App: React.FC = () => {

  const [currentUser, setCurrentUser] = useState<UserContextType>();

  const [orders, setOrders] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [groceries, setGroceries] = useState(null);
  const [categories, setCategories] = useState<any[] | any>(null);
  return (
    <div className="admin">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <UserContext.Provider value={{ currentUser, setCurrentUser }}>
        <CategoriesContext.Provider value={{categories, setCategories}}>
          <GroceriesContext.Provider value={{ groceries, setGroceries }}>
            <OrdersContext.Provider value={{ orders, setOrders }}>
              <CustomersContext.Provider value={{ customers, setCustomers }}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <AdminRoutes>
                        <MainLayout />
                      </AdminRoutes>
                    }
                  >
                    <Route path="/customers" element={<MainLayout />} />
                    <Route path="/customer/:id" element={<MainLayout />} />
                    <Route path="/users" element={<MainLayout />} />
                    <Route path="/user/:id" element={<MainLayout />} />
                    <Route path="/orders" element={<MainLayout />} />
                    <Route path="/groceries" element={<MainLayout />} />
                    <Route path="/categories" element={<MainLayout />} />
                    <Route path="/blogs" element={<MainLayout />} />
                    <Route path="/users" element={<MainLayout />} />
                    <Route path="/messages" element={<MainLayout />} />
                    <Route path="/promotions" element={<MainLayout />} />
                    <Route path="/settings" element={<MainLayout />} />
                    <Route path="/grocery/add-edit" element={<MainLayout />} />
                  </Route>
                  <Route
                    path="/auth/login"
                    element={
                      <AuthRoutes>
                        <AuthLayout />
                      </AuthRoutes>
                    }
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </CustomersContext.Provider>
            </OrdersContext.Provider>
          </GroceriesContext.Provider>
        </CategoriesContext.Provider>
      </UserContext.Provider>
    </div>
  );
};

export default App;
