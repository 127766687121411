import { ArrowLeft, Image } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./styles/page.styles.module.css";
import clsx from "clsx";
import {addGrocery, updateGrocery } from "../utils/databaseHelper";
import { toast } from 'react-toastify';
import { CategoriesContext } from "../utils/context";

const AddProduct: React.FC = () => {
  const [tempImageUrl, setTempImageUrl] = useState<string | null>();
  const [isNewImage, setIsNewImage] = useState(false);
  const [newImageFile, setNewImageFile] = useState();
  const [uploadStatus, setUploadStatus] = useState(false);
  const [isEditing,setIsEditing] = useState(false);
  const {categories} = useContext(CategoriesContext);

  const [formData, setFormData] = useState({
    availability: "",
    category: "",
    productCategory:"",
    description: "",
    imageUrl: "",
    itemId: "",
    itemName: "",
    itemPrice: "",
    quantity: "1",
    unit: "",
    purchaseMargin:0,
  });
  const { state } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      setFormData({ ...state.grocery });
    }
  }, [state.grocery]);


  function onChange(e: any) {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.name === "purchaseMargin"
          ? parseInt(e.target.value)
          : e.target.value,
    }));
  }

  function onSubmit(){
    setUploadStatus(true);
   
    if(state.grocery) {
      
      updateGrocery(formData, isNewImage, newImageFile, setUploadStatus);
    }
     else{ 
      if(!newImageFile){
        toast.error("Image field is required");
        setUploadStatus(false);
        return
      }
      addGrocery(formData, newImageFile, setUploadStatus);
  }
}

  function onImageChange(e:any){
       const file = e.target.files[0];
       const imageUrl = URL.createObjectURL(file);
       setTempImageUrl(imageUrl);
       setIsNewImage(true);
       setNewImageFile(file);
  }

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <Link
          to="/groceries"
          className={clsx(
            styles.back_button,
            "me-3 border px-2 py-1 rounded-1"
          )}
        >
          <ArrowLeft size="15" color="#00000099" />
        </Link>

        <div>
          <span className={styles.caption}>Back to product list</span>
          <h6>{state.grocery ? "Edit Product" : "Add New Product"}</h6>
        </div>
      </div>

      <div className="bg-white px-4 rounded-3 pb-5">
        <div className="row">
          <div className="col col-md-6  ">
            <div className={styles.prod_sub_heading}>Description</div>
            <div className="border rounded-1 px-4 py-3">
              <div className="mb-3">
                <label
                  htmlFor="itemName"
                  className="form-label small text-muted"
                >
                  Product Name
                </label>
                <input
                  type="text"
                  value={formData.itemName}
                  className="form-control small text-muted"
                  id="itemName"
                  name="itemName"
                  onChange={onChange}
                  placeholder="Enter grocery name"
                  required
                  disabled={uploadStatus}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="form-label small text-muted"
                >
                  Product Description
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  value={formData.description}
                  name="description"
                  onChange={onChange}
                  rows={3}
                  disabled={uploadStatus}
                />
              </div>
            </div>
            <div className={styles.prod_sub_heading}>Category</div>
            <div className="border rounded-1 px-4 py-3">
              <div className="mb-3 ctg">
                <div className="d-flex align-items-center justify-content-between">
                  <label
                    className="form-label small text-muted text-capitalize"
                    htmlFor="category"
                  >
                    Product category
                  </label>
                  <span className="material-symbols-rounded">add_box</span>
                </div>

                <select
                  id="productCategory"
                  name="productCategory"
                  onChange={onChange}
                  className="form-select small text-capitalize"
                  aria-label="Product category"
                  required
                  disabled={uploadStatus}
                >
                  <option>select</option>
                  {categories?.map((item, index) => (
                    <option
                      key={index}
                      value={item.id}
                      selected={
                        String(formData.productCategory).toLowerCase() ===
                        String(item.id).toLowerCase()
                      }
                    >
                      {item.name}
                    </option>
                  ))}
                </select>

                <select
                  id="category"
                  name="category"
                  onChange={onChange}
                  className="form-select small text-capitalize"
                  aria-label="Product category"
                  required
                  disabled={uploadStatus}
                >
                  {" "}
                  <option>select</option>
                  {[
                    "vegetables",
                    "fruits",
                    "meats & fish",
                    "offers",
                    "ryans garden",
                    "cooking essentials",
                    "more groceries",
                  ].map((item, index) => (
                    <option
                      key={index}
                      selected={
                        String(formData.category).toLowerCase() ===
                        String(item).toLowerCase()
                      }
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label
                  className="form-label small text-muted text-capitalize"
                  htmlFor="unit"
                >
                  Product unit
                </label>
                <select
                  id="unit"
                  name="unit"
                  onChange={onChange}
                  className="form-select text-capitalize"
                  aria-label="Product unit"
                  required
                  disabled={uploadStatus}
                >
                  <option>select</option>
                  {[
                    "head",
                    "shoot",
                    "pack",
                    "kg",
                    "piece",
                    "bundle",
                    "cluster",
                    "bunch",
                    "fingers",
                    "0.25kg",
                    "cup 500ml",
                    "pepper",
                    "fruit",
                    "pumpkin",
                    "yams",
                    "fruit (Large)",
                    "stem",
                    "kidney",
                    "foot",
                    "half kg",
                    "bird",
                    "fish",
                    "sack",
                    "leaf",
                    "tray",
                  ].map((item, index) => (
                    <option
                      key={index}
                      selected={
                        String(item).toLowerCase() ===
                        String(formData.unit).toLowerCase()
                      }
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={styles.prod_sub_heading}>Inventory</div>
            <div className="border rounded-1 px-4 py-3">
              <div className="row">
                <div className="col">
                  <label
                    className="form-label small text-muted"
                    htmlFor="quantity"
                  >
                    Quantity
                  </label>
                  <input
                    type="number"
                    min={0}
                    id="quantity"
                    onChange={onChange}
                    value={formData.quantity}
                    name="quantity"
                    className="form-control"
                    placeholder="1"
                    required
                    disabled={uploadStatus}
                  />
                </div>
                <div className="col">
                  <label
                    className="form-label small text-muted"
                    htmlFor="availability"
                  >
                    Availability
                  </label>
                  <select
                    className="form-select text-capitalize"
                    id="availability"
                    name="availability"
                    onChange={onChange}
                    required
                    disabled={uploadStatus}
                  >
                    <option>select</option>
                    {["in stock", "out of stock"].map((item) => (
                      <option
                        key={item}
                        selected={
                          String(item).toLowerCase() ===
                          String(formData.availability).toLowerCase()
                        }
                        value={item}
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-md-6 ">
            <div className={styles.prod_sub_heading}>Product Image</div>
            <div className="border rounded-1 px-4 py-3">
              <div className="row">
                <div className="col col-md-4 upl">
                  <label
                    htmlFor="image_file"
                    className={clsx(
                      styles.image_upload,
                      " d-flex align-items-center justify-content-center flex-column px-3 py-5 rounded-3"
                    )}
                  >
                    <span
                      className={clsx(
                        styles.upload_icon,
                        "material-symbols-outlined mb-2"
                      )}
                    >
                      imagesmode
                    </span>
                    {/* <Image size="32" color="#555555" /> */}
                    <span className={styles.upload_label}>Click to upload</span>
                  </label>
                  <input
                    type="file"
                    className="hide_field"
                    name="image_file"
                    id="image_file"
                    onChange={onImageChange}
                    accept="image/png, image/jpeg,image/gif"
                    disabled={uploadStatus}
                  />
                </div>
                <div className="col col-md-6">
                  <div
                    className={clsx(
                      styles.upload_img,
                      "d-flex align-items-center justify-content-center flex-column  px-3 py-4 rounded-3"
                    )}
                  >
                    <img
                      src={
                        tempImageUrl ||
                        formData.imageUrl ||
                        "https://bit.ly/2ut62it"
                      }
                      width="103"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.prod_sub_heading}>Pricing</div>
            <div className="border rounded-1 px-4 py-3">
              <label
                htmlFor="itemPrice"
                className="form-label small text-muted"
              >
                Price
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">UGX</span>
                <input
                  type="number"
                  id="itemPrice"
                  name="itemPrice"
                  onChange={onChange}
                  value={formData.itemPrice}
                  className="form-control"
                  aria-label="Amount (to the nearest shilling)"
                  required
                  disabled={uploadStatus}
                />
                <span className="input-group-text">.00</span>
              </div>
              <label
                htmlFor="itemPrice"
                className="form-label small text-muted"
              >
                Purchase mergin
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">%</span>
                <input
                  type="number"
                  id="ipurchaseMargin"
                  name="purchaseMargin"
                  onChange={onChange}
                  value={formData?.purchaseMargin}
                  min="0"
                  className="form-control"
                  aria-label="Percentage margin "
                  required
                  disabled={uploadStatus}
                />
                <span className="input-group-text">Percent</span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-5">
              <button
                className="btn border"
                onClick={() => navigate("/groceries")}
                disabled={uploadStatus}
              >
                <span className="small">Discard</span>
              </button>
              <button
                className="btn btn-primary btn-sm"
                disabled={uploadStatus}
                onClick={onSubmit}
              >
                <span className="small text-capitalize">
                  {uploadStatus
                    ? "Uploading..."
                    : state.grocery
                    ? "Edit product"
                    : " Add new product"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
