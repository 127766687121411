import clsx from "clsx";
import { ArrowLeft } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import styles from "./styles/page.styles.module.css";
import { CustomersContext } from "../utils/context";
import countries from "../utils/countries.json";
import noImage from "../assets/images/no-image.jpg";
import { editCustomerDetails } from "../utils/databaseHelper";


type imageFileType = {
  tempPath: string;
  file:File | Blob | any ; 
};
const EditCustomer = () => {
  const params = useParams();
  const [customer, setCustomer] = useState<any | null>(null);
  const [formError, setFormError] = useState<string>();
  const [userPicture, setUserPicture] = useState<imageFileType>();
  const { customers } = useContext(CustomersContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    let cust = customers.filter(
      (customer: any) => customer.userId === params.id
    )[0];
    if (cust) setCustomer(cust);
    else setCustomer(null);
  }, [params]);

  function onChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    setCustomer((custInfo: any) => ({
      ...custInfo,
      [e.target.name]: e.target.value,
    }));
  }
  function changePicture( e : React.ChangeEvent<HTMLInputElement>){
    if(e.target.files){
      let file = e.target.files[0];
      let url = URL.createObjectURL(file);
      setUserPicture({tempPath: url, file});
    }

  }

  function onHandleSubmit(e: React.MouseEvent<HTMLFormElement>) {
    e.preventDefault();
    if (
      !customer.userName ||
      !customer.email ||
      !customer.address ||
      !customer.adminArea ||
      !customer.locality ||
      !customer.countryName ||
      !customer.phoneNumber ||
      !customer.accountType
    ) {
      setFormError("All fields are required");
      return;
    }
    setLoading(true);
    editCustomerDetails(customer, setLoading, userPicture?.file);

  }

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <Link
          to="/customers"
          className={clsx(
            styles.back_button,
            "me-3 border px-2 py-1 rounded-1"
          )}
        >
          <ArrowLeft size="15" color="#00000099" />
        </Link>

        <div>
          <span className={styles.caption}>Back to customers list</span>
          <h6>Edit customer</h6>
        </div>
      </div>
      <div className="bg-white p-3">
        {formError && (
          <div className="alert alert-danger py-1 px-3 small">{formError}</div>
        )}
        <form onSubmit={onHandleSubmit}>
          <div className="row p-2">
            <div className="col-ms-6 col-md-6">
              <div className="mb-3">
                <label
                  htmlFor="fullname"
                  className="form-label small text-muted"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  value={customer?.userName}
                  className="form-control small text-muted"
                  id="userName"
                  name="userName"
                  onChange={onChange}
                  placeholder="Enter fullname"
                  required
                  disabled={loading}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label small text-muted">
                  Email
                </label>
                <input
                  type="email"
                  value={customer?.email}
                  className="form-control small text-muted"
                  id="email"
                  name="email"
                  onChange={onChange}
                  placeholder="Enter emails"
                  required
                  disabled={loading}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="phoneNumber"
                  className="form-label small text-muted"
                >
                  Phone number
                </label>
                <input
                  type="phoneNumber"
                  value={customer?.phoneNumber}
                  className="form-control small text-muted"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={onChange}
                  required
                  placeholder="Enter phone number"
                  disabled={loading}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="itemName"
                  className="form-label small text-muted"
                >
                  addres
                </label>
                <input
                  type="text"
                  value={customer?.address}
                  className="form-control small text-muted"
                  id="address"
                  name="address"
                  onChange={onChange}
                  placeholder="Ex. Stretcher Rd, Ntinda, Kampala"
                  required
                  disabled={loading}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="region" className="form-label small text-muted">
                  Region
                </label>
                <select
                  value={customer?.region}
                  className="form-select small text-muted text-capitalize"
                  id="region"
                  name="region"
                  onChange={onChange}
                  required
                  disabled={loading}
                >
                  <option>Select region</option>
                  {[
                    "central region",
                    "western region",
                    "northern region",
                    "eastern region",
                  ].map((item, index) => (
                    <option
                      selected={
                        String(customer?.adminArea).toLowerCase() === item.toLowerCase()
                      }
                      value={item}
                      key={index}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="nationallity"
                  className="form-label small text-muted"
                >
                  Nationallity
                </label>
                <select
                  value={customer?.countryName}
                  className="form-select small text-muted"
                  id="nationallity"
                  name="countryName"
                  onChange={onChange}
                  required
                  disabled={loading}
                >
                  <option>Select nationality</option>
                  {countries.map((country, index) => (
                    <option
                      selected={
                        String(country.name).toLowerCase() === customer?.countryName
                      }
                      value={country.name}
                      key={index}
                    >
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="locality"
                  className="form-label small text-muted"
                >
                  Location
                </label>
                <input
                  type="text"
                  value={customer?.locality}
                  className="form-control small text-muted"
                  id="locality"
                  name="locality"
                  onChange={onChange}
                  placeholder="Enter location"
                  required
                  disabled={loading}
                />
              </div>
            </div>
            <div className="col-ms-6 col-md-4 py-3">
              <fieldset className="border rounded-2 p-2 position-relative mb-3">
                <legend
                  className="w-auto position-absolute small text-muted"
                  style={{
                    backgroundColor: "#fff",
                    top: "-11px",
                    padding: "0 5px",
                    left: "10px",
                  }}
                >
                  User Picture
                </legend>
                <div className="d-flex align-items-center gap-3 my-2">
                  <img
                    className="rounded-circle border border-muted border-1"
                    src={userPicture?.tempPath || customer?.imageUrl || noImage}
                    width="80"
                    height="80"
                    alt="user picture"
                  />
                  <label
                    htmlFor="userPicture"
                    className="btn btn-sm btn-outline-primary"
                  >
                    <span className="small">Change</span>
                  </label>
                  <input
                    type="file"
                    accept="image"
                    name="userPicture"
                    id="userPicture"
                    className="hide_field"
                    onChange={changePicture}
                  />
                </div>
              </fieldset>

              <div className="mb-3">
                <label className="form-label small text-muted">
                  Account type
                </label>
                <select
                  className="form-select small text-muted text-capitalize"
                  id="accountType"
                  name="accountType"
                  onChange={onChange}
                  disabled={loading}
                >
                  <option>Select account type</option>
                  {["admin", "manager", "customer"].map((item, index) => (
                    <option
                      selected={
                        item === String(customer?.accountType).toLowerCase()
                      }
                      value={item}
                      key={index}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="cluster"
                  className="form-label small text-muted"
                >
                  Account cluster
                </label>
                <select
                  className="form-select small text-muted text-capitalize"
                  id="cluster"
                  name="cluster"
                  onChange={onChange}
                  disabled={loading}
                >
                  <option>Select cluster type</option>
                  {["cluster 1", "cluster 2", "cluster 3"].map(
                    (item, index) => (
                      <option
                        selected={
                          item === String(customer?.cluster).toLowerCase()
                        }
                        value={item}
                        key={index}
                      >
                        {item}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="accountStatus"
                  className="form-label small text-muted"
                >
                  Account status
                </label>
                <select
                  className="form-select small text-muted text-capitalize"
                  id="accountStatus"
                  name="accountStatus"
                  onChange={onChange}
                  disabled={loading}
                >
                  <option>Select status</option>
                  {["active", "blocked"].map((item, index) => (
                    <option
                      selected={
                        item === String(customer?.accountStatus).toLowerCase()
                      }
                      value={item}
                      key={index}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex align-items-center gap-3">
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() => navigate("/customers")}
                  disabled={loading}
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm w-75 small"
                >
                  {loading ? "Loading..." : " Update details"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditCustomer;
