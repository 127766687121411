import { auth, storage, db } from "./firebaseConfig";
import { ref, set, get, onValue, update, remove } from "firebase/database";
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import React from "react";
import { toast } from "react-toastify";
import ErrorHandler from "./ErrorHandler";
import { customerType } from "../App";

function generateRandom(): string {
  let alpNum: string =
    "ABCDEFGHIJKLOMNOPQRSTUVWXYZabcdefghijklomnopqrstuvwxyz1234567890";
  let wordArray: string[] = [];
  let finalString: string = "";
  for (let i = 0; i < 18; i++) {
    let chr = Math.floor(Math.random() * alpNum.length);

    wordArray.push(alpNum.charAt(chr));
  }

  finalString = wordArray.join("");
  return finalString;
}

export const getBlogs = (
  setBlogs: React.Dispatch<React.SetStateAction<any | []>>,
  setLoadingState: React.Dispatch<
    React.SetStateAction<{
      posting: boolean;
      loading: boolean;
    }>
  >
) => {
  try {
    onValue(ref(db, "Blogs"), (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val();
        let blogList: object[] = [];

        Object.values(data).map((obj: object | any) => {
          blogList.push(obj);
        });

        blogList.sort(
          (a: number | any, b: number | any) => b.postDate - a.postDate
        );
        setBlogs(blogList);
        setLoadingState((prev) => ({ ...prev, loading: false }));
      } else {
        setBlogs([]);
        setLoadingState((prev) => ({ ...prev, loading: false }));
        return;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const addBlog = async (
  data: any,
  setLoadingState: React.Dispatch<
    React.SetStateAction<{
      posting: boolean;
      loading: boolean;
    }>
  >
) => {
  let response: string | null | object;
  setLoadingState((prev) => ({ ...prev, posting: true }));

  let blogId = generateRandom();

  const uploadTask = uploadBytesResumable(
    storageRef(storage, "blogs/" + blogId),
    data.image
  );

  uploadTask.on(
    "state_changed",
    (snapshot) => {},
    (error) => {
      response = { status: "failed to upload", error: error };
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(async (downloadUrl) => {
        console.log(downloadUrl);
        await set(ref(db, "Blogs/" + blogId), {
          ...data,
          id: blogId,
          postDate: Date.now(),
          image: downloadUrl,
        });
        setLoadingState((prev) => ({ ...prev, posting: false }));
        response = { status: "success" };
      });
    }
  );
};

export const updateBlog = async (
  id: string,
  data: object | any,
  setLoadingState: React.Dispatch<
    React.SetStateAction<{
      posting: boolean;
      loading: boolean;
    }>
  >
) => {
  try {
    setLoadingState((state) => ({ ...state, posting: true }));
    let imageUrl: string | Promise<string> = "";

    if (typeof data.image === "object" || data.image.hasOwnProperty("size")) {
      //New image exists in
      console.log("New image exists");

      const uploadTask = uploadBytesResumable(
        storageRef(storage, "blogs/" + id),
        data.image
      );

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          return console.log(error, "failed to upload image");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadUrl) => {
            await runUpdate(id, { ...data, image: downloadUrl });
            setLoadingState((state) => ({ ...state, posting: false }));
          });
        }
      );
    } else {
      await runUpdate(id, data);
      setLoadingState((state) => ({ ...state, posting: false }));
    }
  } catch (error) {
    console.log(error);
  }
};

async function runUpdate(id: string, obj: object) {
  await update(ref(db, "Blogs/" + id), obj).then(() => {
    console.log("Blog updated successfully");
  });
}

export const deleteBlog = async (
  id: string,
  setMessage: React.Dispatch<React.SetStateAction<string | null>>
) => {
  try {
    deleteObject(storageRef(storage, "blogs/" + id))
      .then(async () => {
        await remove(ref(db, "Blogs/" + id));
        setMessage("Article deleted successfully");
      })
      .catch((error) => console.log(error));
  } catch (error) {
    console.log(error);
  }
};

export const fetchGroceries = async (setGroceries: any) => {
  try {
    onValue(ref(db, "Groceries"), (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        setGroceries(data);
      }
    });
  } catch (error) {
    setGroceries([]);
    //Display error to the user
  }
};

export const addGrocery = async (
  grocery: any,
  imageData: any,
  setUploadStatus: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    let id = generateRandom();

    const uploadTask = uploadBytesResumable(
      storageRef(storage, "images/" + id),
      imageData
    );
    uploadTask.on(
      "state_changed",
      () => {},
      (error) => {
        console.log(error);
        toast.error("Failed to add new grocery");
        setUploadStatus(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (dowloadUlr) => {
          await set(ref(db, "Groceries/" + id), {
            ...grocery,
            itemId: id,
            imageUrl: dowloadUlr,
          });
          toast.success("Product added successfully");
          setUploadStatus(false);
        });
      }
    );
  } catch (error: any) {
    console.log(error.code);
    toast.error("Could not add item");
    setUploadStatus(false);
  }
};

export const updateGrocery = async (
  formData: any,
  isNewImage: boolean,
  imageData: any,
  setUploadStatus: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    if (isNewImage === true) {
      const uploadTask = uploadBytesResumable(
        storageRef(storage, "images/" + formData.itemId),
        imageData
      );
      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          toast.error(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadUlr) => {
            await update(ref(db, "Groceries/" + formData.itemId), {
              ...formData,
              imageUrl: downloadUlr,
            });
          });
        }
      );
    } else {
      await update(ref(db, "Groceries/" + formData.itemId), formData);
    }

    toast.success("Product updated successfully");
    setUploadStatus(false);
  } catch (error: any) {
    setUploadStatus(false);
    toast.error(error.message);
  }
};

export const getOrders = async (setOrders: any) => {
  try {
    onValue(ref(db, "Orders"), (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        setOrders(data);
      }
    });
  } catch (error) {
    setOrders([]);
    toast.error("failed to fetch orders");
    //Do something with the error
  }
};
export const updateOrderAction = async (
  action: object | any,
  setNewActions: React.Dispatch<React.SetStateAction<any>>
) => {
  try {
    set(ref(db, `Orders/${action.orderId}/Actions/${action.itemId}`), {
      ...action,
    }).then(() => {
      toast.success("Tracker updated successfully");
      setNewActions((prev: any) => [...prev, action]);
    });
  } catch (error) {
    toast.error("Tracker update failed");
  }
};

export const getCustomers = async (setCustomers: any) => {
  try {
    onValue(ref(db, "Users"), (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        setCustomers(data);
      }
    });
  } catch (error: any) {
    //do something here
    setCustomers([]);
    toast.error(error.message);
  }
};

export const editCustomerDetails = async (
  user: customerType,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  file: File
) => {
  try {
    // console.log(user);

    // return;
    if (file) {
      const uploadTask = uploadBytesResumable(
        storageRef(storage, "images/" + user.userId),
        file
      );
      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          throw new Error(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadUlr) => {
            await update(ref(db, `Users/${user.userId}`), {
              ...user,
              imageUrl: downloadUlr,
            });
            toast.success("Customer information updated successfully");
            setLoading(false);
          });
        }
      );
    } else {
      update(ref(db, `Users/${user.userId}`), { ...user }).then(() => {
        toast.success("Customer information updated successfully");
        setLoading(false);
      });
    }
  } catch (error: any) {
    toast.error(ErrorHandler(error.emessage));
    setLoading(false);
  }
};

export const addProductCategory = async (formData: any, imageFile: any) => {
  try {
    //check if imageFile exists
    //upload the image and return the url
    //upload category
    let id = generateRandom();
    let imageUrl = "";
    if (imageFile) {
      imageUrl = await uploadImageToDatabase(id, imageFile, "categories");
    }

    await set(ref(db, "categories/" + id), {
      ...formData,
      id,
      imageUrl: imageUrl ? imageUrl : formData.imageUrl,
    });

    toast.success("Product category added successfully");
  } catch (error) {
    console.log(error);
    toast.error("Product category failed");

    throw error;
  }
};

export const uploadImageToDatabase = async (
  name: string,
  image: any,
  folder: any
) => {
  const uploadTask = uploadBytesResumable(
    storageRef(storage, `${folder}/${name}`),
    image
  );

  const imageUrl = await new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      () => {},
      (error) => {
        console.error(error);
        toast.error("Failed to upload image");
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          resolve(downloadUrl);
        });
      }
    );
  });
  // console.log(imageUrl);
  return String(imageUrl);
};

export const getProductCategories = async (setCategories: any) => {
  try {
    onValue(ref(db, "categories"), (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        setCategories(data);
      }
    });
  } catch (error: any) {
    setCategories([]);
    console.error(error);
    toast.error("Failed to fetch product categories");
  }
};

export const updateProductCategories = async (
  category: any,
  imageFile: any
) => {
  try {
    let imageUrl = "";

    if (imageFile) {
      imageUrl = await uploadImageToDatabase(
        category.id,
        imageFile,
        "categories"
      );
    }
    await update(ref(db, "categories/" + category.id), {
      ...category,
      imageUrl: imageUrl ? imageUrl : category.imageUrl,
    });
  } catch (error) {
    console.log(error);
    toast.error("Failed to update category");
  }
};
