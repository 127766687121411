import { auth, db, storage } from "./firebaseConfig";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { ref, get, child, onValue } from "firebase/database";
import { redirect } from "react-router-dom";
import ErrorHandler from "./ErrorHandler";
type formDataType = {
  email: string;
  password: string;
};

export const loginWithEmailAndPassword = async (
  formData: formDataType,
  setFormError: React.Dispatch<
    React.SetStateAction<{
      emailError: string;
      credentialsError: string;
    }>
  >,
  setCurrentUser: React.Dispatch<React.SetStateAction<object | null>>,
  setLoggingInState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    let user = await signInWithEmailAndPassword(
      auth,
      formData.email,
      formData.password
    ).then((user: any) => user);
    //check if the user is admin

    getCurrentUserInfo(user.user.uid, setCurrentUser);
  } catch (error: any) {
    setFormError((prev) => ({
      ...prev,
      credentialsError: ErrorHandler(
        error.message
          .replace(/[(,),.]+/g, "")
          .trim()
          .split(" ")
          .at(-1)
      ),
    }));
    setCurrentUser(null);
    setLoggingInState(false);
    signOut(auth);
    console.log(error);
  }
};

export const getCurrentUserInfo = async (
  id: string,
  setCurrentUser: React.Dispatch<React.SetStateAction<object | any>>
) => {
  try {
    onValue(ref(db, "Users/" + id), (snapshot: any) => {
      if (snapshot.val()) {
        setCurrentUser({ ...snapshot.val() });
      } else {
        setCurrentUser(null);
        throw new Error("Unauthorized");
      }
    });
  } catch (error: any) {
    console.log(error.message);
  }
};

export const logUserOut = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem("user");
    redirect("/auth/login");
  } catch (error: any) {
    console.log(error.message);
  }
};
