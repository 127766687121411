import clsx from "clsx";
import React from "react";
import styles from "../../styles/page.styles.module.css";
import { getStatus, orderType } from "../../Orders";
import {useNavigate } from 'react-router-dom'
type newOrderType = {
  orders: orderType[];
};

const RecentOrders: React.FC<newOrderType> = ({ orders }) => {
    const navigate = useNavigate();
  return (
    <div className="rounded-3 bg-white px-4 py-4 mt-4">
      <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div className={clsx(styles.card_label)}>Recent Orders</div>
        <button className="btn btn-sm text-capitalize" onClick={() => navigate("/orders")}>
          <span className="small">View all</span>
        </button>
      </div>

      <div>
        <table className="table">
          <thead>
            <tr className="small">
              {["Order code", "Created", "Customer", "Status", "Method"].map(
                (item, index) => (
                  <td key={index} className="small fw-bold text-secondary">{item}</td>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {orders.slice(0, 10).map((order) => (
              <tr key={order.order_code} className="small text-capitalize">
                <td className="small">{order.order_code}</td>
                <td className="small">{order.date}</td>
                <td className="small">
                  <div className="">
                    <img
                      src="https://bit.ly/3A6xFzI"
                      width="25"
                      className={styles.table_avatar}
                    />
                    <span>{order.userName}</span>
                  </div>
                </td>
                <td className="small">{getStatus(order.Actions)}</td>
                <td align="right" className="small">
                  {order.payment_method}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentOrders;
