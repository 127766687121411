import clsx from 'clsx';
import styles from '../../styles/page.styles.module.css';
import { ResponsivePie } from "@nivo/pie";
import OrdersPie from './OrdersPie';
import OrdersBarChart from './OrdersBarChart';
import { useNavigate } from 'react-router-dom';

type OrdersProps = {
    orders: {
        [key:string] : any,
        status:string
        Actions:{
            [key:string]: any,
            status:string
        }[]
    }[]
}

const OrderSummery: React.FC<OrdersProps> = ({ orders }) => {

  const navigate = useNavigate();
  function filterOrders(filterText: string): number {
    let ord = orders.filter((order) => {

      if(order.hasOwnProperty("Actions"))
      return (
        String(Object.values(order?.Actions).at(-1)?.status).toLowerCase() ===
        filterText
      );
    }).length;
    return ord;
  }
  return (
    <div className="bg-white rounded-3 px-4 pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
        {" "}
        <div className={clsx(styles.card_label)}>Orders Summery</div>
        <button
          className="btn btn-sm text-capitalize"
          onClick={() => navigate("/orders")}
        >
          <span className="small">View all</span>
        </button>
      </div>

      <div className="row cols-12 cols-md-3 text-center mt-2 mb-3">
        <div className="col">
          <div className={styles.ordr_nums}>{filterOrders("complete")}</div>
          <div className={clsx(styles.ordr_sta, "text-muted")}>Complete</div>
        </div>
        <div className="col">
          <div className={styles.ordr_nums}>{filterOrders("pending")}</div>
          <div className={clsx(styles.ordr_sta, "text-muted")}>Pending</div>
        </div>
        <div className="col">
          <div className={styles.ordr_nums}>{filterOrders("canceled")}</div>
          <div className={clsx(styles.ordr_sta, "text-muted")}>Canceled</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9" style={{ height: "150px" }}>
          <OrdersPie 
          complete={filterOrders("complete")} 
          canceled={filterOrders("canceled")}
          pending={filterOrders("pending")}
          
          />
        </div>
      </div>
    </div>
  );
};

export default OrderSummery;