import { ResponsivePie } from '@nivo/pie';
import React from 'react'

const OrdersPie = ({complete, pending, canceled} : any) => {
      const data = [
        {
          id: "Complete",
          label: "Complete",
          value: complete,
        },
        {
          id: "Pending",
          label: "Pending",
          value: pending,
          color: "hsl(96, 70%, 50%)",
        },
        {
          id: "Canceled",
          label: "Canceled",
          value: canceled,
          color: "hsl(209, 83%, 55%)",
        },
      ];
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 20, right: 5, bottom: 20, left: 5 }}
      innerRadius={0.55}
      activeOuterRadiusOffset={4}
      colors={{ scheme: "category10" }}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0]],
      }}
      enableArcLinkLabels={true}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextOffset={5}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsDiagonalLength={10}
      arcLinkLabelsStraightLength={13}
      arcLinkLabelsThickness={1}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabelsRadiusOffset={0.1}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
    
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 29,
          translateY: 4,
          itemsSpacing: 0,
          itemWidth: 99,
          itemHeight: 45,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 15,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
}

export default OrdersPie