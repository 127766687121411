import React from 'react'
import fruitIcon from "../../../assets/icons/fruits.png";
import vegeIcon from "../../../assets/icons/vegetables.png";
import offersIcon from "../../../assets/icons/offers.png";
import ryanIcon from "../../../assets/icons/ryans.png";
import spiceIcon from "../../../assets/icons/spice.png";
import stapleIcon from "../../../assets/icons/staple.png";
import meatFishIcon from "../../../assets/icons/meat-fish.png";
import clsx from 'clsx';
import styles from '../../styles/page.styles.module.css';
import { Add } from 'iconsax-react';

const Categories = () => {

    const categories = [
      { title: "vegetables", icon: vegeIcon, id: 0 },
      { title: "fruits", icon: fruitIcon, id: 1 },
      { title: "Meats & fish", icon: meatFishIcon, id: 2 },
      { title: "offers", icon: offersIcon, id: 3 },
      { title: "Ryans garden", icon: ryanIcon, id: 4 },
      { title: "Cooking essentials", icon: spiceIcon, id: 5 },
      { title: "More groceries", icon: stapleIcon, id: 6 },
    ];
  return (
    <div className="rounded-3 bg-white mt-4 px-4 py-4">
      <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div className={clsx(styles.card_label)}>Categories</div>
        <button className="btn btn-light btn-sm">
          <Add size="20" />
        </button>
      </div>
      <div className="">
        {categories.map((category) => (
          <div
            key={category.id}
            className="d-flex align-items-center gap-1 pt-2 border-bottom"
          >
            <img src={category.icon} alt="icon" width="40" />
            <div className={clsx(styles.top_sell_, "text-capitalize")}>
              {category.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Categories