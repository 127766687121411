import React, { useContext, useState, useEffect } from "react";
import { CustomersContext } from "../utils/context";
import { Link } from "react-router-dom";
import { Eye, Trash, Edit, TextalignRight } from "iconsax-react";
import styles from "./styles/page.styles.module.css";
import clsx from "clsx";
import { customerType } from "../App";

const Users: React.FC = () => {
  const { customers } = useContext(CustomersContext);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (customers) setFilteredList(customers);
  }, [customers]);

  function searchByName(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.value !== "") {
      let result = customers.filter((user: any) =>
        String(user.userName)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
      setFilteredList(result);
    } else setFilteredList(customers);
  }
  return (
    <div className="bg-white p-4 rounded-3 mb-4">
      <div className="d-flex align-items-center justify-content-between mb-4">
        {" "}
        <h5>Team members</h5>
        <div>
          <input
            type="search"
            className="form-control"
            placeholder="Search by name"
            onChange={searchByName}
          />
        </div>
      </div>

      {customers && (
        <>
          {filteredList.length ? (
            <>
              <table className="table table-bordered ">
                <thead>
                  <tr className="text-capitalize small">
                    {[
                      "Full name",
                      "email",
                      "phone",
                      "Access level",
                      "Status",
                      "action",
                    ].map((item: any) => (
                      <td key={item}>{item}</td>
                    ))}
                  </tr>
                </thead>
                <tbody className="small">
                  {filteredList
                    .filter((user: customerType) =>
                      ["admin", "manager"].includes(
                        String(user?.accountType).toLowerCase()
                      )
                    )
                    .map((user: object | any) => (
                      <tr key={user.userId} className="small text-capitalize">
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={user.imageUrl || "https://bit.ly/3H3CnSn"}
                              width="40"
                              height="40"
                              className="rounded-circle"
                            />
                            <div>{user.userName}</div>
                          </div>
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{user?.accountType}</td>
                        <td>{user.accountStatus || "Uknown"}</td>

                        <td>
                          <div className="d-flex align-items-center gap-3 justify-content-around">
                            <Link to={`/user/${user.userId}`}>
                              <Eye
                                size="15"
                                color="#6eb140"
                                className={styles.action_button}
                              />
                            </Link>
                            <Trash
                              size="15"
                              color="#FF0000"
                              className={styles.action_button}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          ) : (
            <div>No results</div>
          )}
        </>
      )}

      {!customers && "Loading"}
    </div>
  );
};

export default Users;
