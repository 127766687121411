import clsx from "clsx";
import { TickCircle } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { updateOrderAction } from "../utils/databaseHelper";

type actionTypes = {
  actions: {
    date: string;
    itemId: number;
    message: string | any;
    orderId: string;
    status: string;
  }[];
  expandTrackerWindow: boolean;
  id: string;
};
type actionType = {
  date: string;
  itemId: number;
  message: string | any;
  orderId: string;
  status: string | null;
};

const OrderActionsComponent = ({
  actions,
  id,
  expandTrackerWindow,
}: actionTypes) => {
  const [formError, setFormError] = useState<string | null>(null);
  const [newActions, setNewActions] = useState(actions);
  function statusClass(status: string) {
    if (status?.toLowerCase() === "pending") return "text-info";
    if (status?.toLowerCase() === "cancelled") return "text-danger";
    return "text-success";
  }
  useEffect(() => {
    setNewActions(actions);
  }, [actions]);
  const buttons = [
    {
      id: 1,
      label: "confirm order",
      message: "We are confirming your order",
      btnClass: "info",
    },
    {
      id: 2,
      label: "Preparing order",
      message: "Order is being prepared",
      btnClass: "warning",
    },
    {
      id: 3,
      label: "rider on the way",
      message: "Rider is on the way",
      btnClass: "primary",
    },
    {
      id: 4,
      label: "rider has arrived",
      message: "Rider has arrived ",
      btnClass: "secondary",
    },
    {
      id: 5,
      label: "order delivered",
      message: "Your order has been delivered ",
      btnClass: "info",
    },
    {
      id: 6,
      label: "cancel order",
      message: "Your order has been cancelled",
      btnClass: "danger",
    },
  ];
  const [formData, setFormData] = useState<actionType>({
    date: String(
      new Date().toLocaleDateString("en-us") +
        " " +
        new Date().toLocaleTimeString("en-us")
    ),
    itemId: actions.length,
    message: "",
    orderId: id,
    status: null,
  });
  let styles: any = {
    visibility: expandTrackerWindow ? "visible" : "hidden",
    opacity: expandTrackerWindow ? "1" : "0",
    transition: "visibility .9s .5s fade-in,visibility .9s .8s slide-in",
  };

  function onChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>
  ) {
    setFormError(null);
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (!formData.message || !formData.status) {
      setFormError("All fields are required");
      return;
    }
    // console.log(formData)
    updateOrderAction(formData, setNewActions);

    //update order
  }

  return (
    <div
      className="px-4 pt-4 mt-4 mb-5 hide-scrollbar"
      style={{ overflow: "scroll", maxHeight: "100vh" }}
    >
      <h6 className="mt-3 mb-3 text-capitalize">Edit order</h6>
      {Object.values(newActions)?.map((item) => (
        <div
          className="d-flex align-items-start tracker_card position-relative small "
          key={item?.itemId}
          style={{
            ...styles,
          }}
        >
          <TickCircle size="20" className="text-success" />

          <div className="border rounded-2 mb-3 px-3 py-3 small flex-1 w-100 ms-2 shadow-sm">
            <div className="small text-muted">{item?.date}</div>
            <div className="py-1">{item?.message}</div>
            <div className={clsx(statusClass(item?.status), "text-capitalize")}>
              {item?.status}
            </div>
          </div>
        </div>
      ))}
      <div
        className="shadow-sm border rounded-2 py-2 px-3 mt-3"
        style={{
          ...styles,
        }}
      >
        <h6 className="mt-3 text-muted">Status tracker</h6>
        {formError && (
          <span className="text-danger" style={{ fontSize: "11px" }}>
            {formError}
          </span>
        )}
        <textarea
          name="message"
          value={formData?.message}
          className="form-control form-control-sm border_bottom mb-3"
          onChange={onChange}
        />
        <select
          name="status"
          className="form-select form-select-sm small text-capitalize"
          onChange={onChange}
        >
          <option>Select tracker status</option>
          {["complete", "pending","cancelled"].map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
        <button
          className="btn btn-sm btn-primary w-100 my-3"
          onClick={handleSubmit}
        >
          <span className="small">Update</span>
        </button>
      </div>
      <div
        className="my-5"
        style={{
          ...styles,
        }}
      >
        {" "}
        {buttons
          .sort((a: any, b: any) => a.id - b.id)
          .map((item, index) => (
            <button
              className={`btn btn-sm btn-outline-${item.btnClass} m-2 text-capitalize`}
              key={item.id}
              onClick={() =>
                setFormData((prev) => ({
                  ...prev,
                  message: buttons[index].message,
                }))
              }
            >
              <span className="small">{item.label}</span>
            </button>
          ))}
      </div>
    </div>
  );
};

export default OrderActionsComponent;
