import { useContext, useEffect, useState, useRef } from "react";
import { GroceriesContext } from "../../utils/context";
import {
  Arrow,
  ArrowLeft,
  ArrowRight,
  CloseCircle,
  CloseSquare,
  Printer,
} from "iconsax-react";
import styles from "../styles/page.styles.module.css";
import clsx from "clsx";
import OrderActinsComponent from "../../components/OrderActions.component";
import PurcahaseList from "./PurcahaseList";

import ReactToPrint from "react-to-print";

export type orderInviewPropType = {
  orderInView: {
    open: boolean;
    data: {
      itemId: string;
      order_code: string;
      deliveryFee: string;
      totalFee: string;
      Cart: any[];
      Actions: any[];
      subTotal: string;
      orderId: string;
    };
  };
  setOrderInView: React.Dispatch<React.SetStateAction<any>>;
};

const OrderDetails: React.FC<orderInviewPropType> = ({
  orderInView,
  setOrderInView,
}) => {
  const { groceries } = useContext(GroceriesContext);
  const [expandTrackerWindow, setExpandTrackerWindow] = useState(false);
  const currencyFormatter = Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "UGX",
  });
   const printRef = useRef<HTMLDivElement>(null);

  function itemDetails(itemId: string) {
    let item = groceries.filter((i: any) => i.itemId === itemId)[0];
    return item;
  }
  return (
    <>
      <div
        className={clsx(
          styles.order_details_wrapper,
          orderInView.open === true && styles.order_details_wrapper_open
        )}
      >
        <div className={clsx(styles.order_details_inner, "hide-scrollbar")}>
          <CloseCircle
            color="#00000080"
            className={styles.close_button}
            size="25"
            onClick={() =>
              setOrderInView({
                open: false,
                data: null,
              })
            }
          />
          <div className="d-flex align-items-center justify-content-between small my-3">
            <h5 className="">
              Order items{" "}
              <span className="text-muted" style={{ fontSize: "14px" }}>
                ( {orderInView.data.order_code})
              </span>
            </h5>
            <span className="">
              {orderInView?.data &&
                Object.values(orderInView?.data?.Cart)?.length}
              <span className="ms-1">Items</span>
            </span>
          </div>
          <div
            className={clsx(styles.order_cart_items, "hide-scrollbar small")}
          >
            {orderInView?.data &&
              Object.values(orderInView.data.Cart).map((item: any) => (
                <div
                  key={item.itemId}
                  className="mb-3 border-bottom pb-3 small"
                >
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={itemDetails(item?.itemId)?.imageUrl}
                        width="50"
                      />
                    </div>
                    <div>
                      <h6>{itemDetails(item.itemId)?.itemName}</h6>

                      <div className="d-flex align-items-center justify-content-between gap-5">
                        <div>
                          <div className="text-muted">Price</div>{" "}
                          <span className={styles.ord_labels}>
                            {currencyFormatter.format(item?.itemPrice)}
                          </span>
                        </div>
                        <div>
                          <div className="text-muted">Qty</div>
                          <span className={styles.ord_labels}>
                            x {item?.itemCount}
                          </span>
                        </div>
                        <div>
                          <div className="text-muted">Total</div>{" "}
                          <span className={styles.ord_labels}>
                            {currencyFormatter.format(item?.itemTotal)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="border p-3 rounded bg-light small">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className={styles.ord_labels}>Delivery</span>{" "}
              {orderInView.data?.deliveryFee}
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className={styles.ord_labels}>Sub Total</span>{" "}
              {orderInView.data?.subTotal}{" "}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className={styles.ord_labels}>Total</span>{" "}
              {orderInView.data?.totalFee}{" "}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap  mt-5 ">
            <button
              className="btn btn-sm btn-outline-secondary"
              data-bs-toggle="modal"
              data-bs-target="#puchaseListModal"
            >
              Download Purchase List
            </button>

            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => setExpandTrackerWindow((prev) => !prev)}
            >
              {!expandTrackerWindow ? (
                <>
                  {" "}
                  <span className="small">Expand to edit order</span>{" "}
                  <ArrowRight size="15" />
                </>
              ) : (
                <>
                  <ArrowLeft size="15" />
                  <span className="small ms-1">Click Collapse</span>{" "}
                </>
              )}
            </button>
          </div>
        </div>
        <div
          className="bg-white pb-5"
          style={{
            borderLeft: "1px solid #dee2e6",
            width: expandTrackerWindow ? "350px" : "0",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <OrderActinsComponent
            actions={orderInView.data?.Actions}
            id={orderInView.data?.orderId}
            expandTrackerWindow={expandTrackerWindow}
          />
        </div>
      </div>

      <div
        className="modal fade"
        id="puchaseListModal"
        aria-labelledby="puchaseListModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body" ref={printRef}>
              <PurcahaseList
                orderCode={orderInView.data.order_code}
                cartItems={orderInView.data?.Cart}
              />
            </div>
            <div className="modal-footer">
              <ReactToPrint
                pageStyle="padding-top:20px"
                documentTitle={`Order - ${orderInView.data.order_code}`}
                trigger={() => (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                  >
                    <Printer /> Print
                  </button>
                )}
                content={() => printRef.current}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
