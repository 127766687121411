import React, { useContext } from "react";
import {
  OrdersContext,
  GroceriesContext,
  CustomersContext,
} from "../utils/context";
import StatisticsGrid from "./components/dashboard/StatisticsGrid";
import { ShoppingBag } from "iconsax-react";
import OrderSummery from "./components/dashboard/OrderSummery";
import TopSellingItems from "./components/dashboard/TopSellingItems";
import RecentOrders from "./components/dashboard/RecentOrders";
import Categories from "./components/dashboard/Categories";
import CircularSekeleton from "../components/loading/CircularSekeleton";
const Dashboard = () => {
  const { orders } = useContext(OrdersContext);
  const { groceries } = useContext(GroceriesContext);
  const { customers } = useContext(CustomersContext);
  return (
    <>
      <div className="row">
        <div className="col col-sm-12 col-md-8">
          {orders && groceries && customers ? (
            <div className="d-flex align-items-center gap-3">
              <StatisticsGrid
                bg="#FFA51E"
                icon={<ShoppingBag size="20" color="#FFFFFF" />}
                text="Orders"
                count={orders.length}
              />
              <StatisticsGrid
                bg="#0C7CE2"
                icon={<ShoppingBag size="20" color="#FFFFFF" />}
                text="Products"
                count={groceries.length}
              />
              <StatisticsGrid
                bg="#FF4765"
                icon={<ShoppingBag size="20" color="#FFFFFF" />}
                text="Customers"
                count={customers.length}
              />
              <StatisticsGrid
                bg="#00AD1C"
                icon={<ShoppingBag size="20" color="#FFFFFF" />}
                text="Categories"
                count={7}
              />
            </div>
          ) : (
            <div className="d-flex  align-items-center gap-3">
           { [1,2,3,4].map((arr) => <div key={arr}><CircularSekeleton /></div>)}
            </div>
          )}

          {orders && (
            <>
              <div className="mt-4">
                <OrderSummery orders={orders} />
              </div>
              <RecentOrders orders={orders} />
            </>
          )}
        </div>
        {groceries && (
          <>
            {" "}
            <div className="col col-sm-12 col-md-4">
              <TopSellingItems groceries={groceries} />
              <Categories />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
