import React, { useEffect, useContext, useState, useRef } from "react";
import { CustomersContext, OrdersContext } from "../utils/context";
import styles from "./styles/page.styles.module.css";
import clsx from "clsx";
import { Eye, Trash } from "iconsax-react";
import OrderDetails from "./components/OrderDetails";
import { convertToDateSting, stringToTimestamp } from "../utils/functions";

type actionObject = {
  [key: string]: any;
  status: string;
};

export type orderType = {
  [key: string]: any;
  userName: string;
  order_code: string;
  itemId: string;
  totalFee: string;
  payment_method: string;
  date: string;
  Actions: {
    [key: string]: any;
    status: string;
  };
};

type customerType = {
  [key: string]: any;
  imageUrl: string;
};

export function getStatus(object: actionObject) {
  if (object) {
    const obj: actionObject = Object.values(object).at(-1);
    return obj.status;
  }
  return "unknown";
}

const Orders: React.FC = () => {
  const { orders } = useContext(OrdersContext);
  const { customers } = useContext(CustomersContext);
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [activeTab, setActiveTab] = useState(0);
  const [orderInView, setOrderInView] = useState({
    open: false,
    data: {
      itemId: "",
      order_code: "",
      deliveryFee: "",
      totalFee: "",
      Cart: [],
      Actions: [],
      subTotal: "",
      orderId: "",
    },
  });

  useEffect(() => {
    if (orders) setFilteredOrders(orders);
  }, [orders]);

  useEffect(() => {
    if (orders) {
      if (activeTab === 1) {
        let ords = orders.filter((order: orderType) => {
          if (order.hasOwnProperty("Actions"))
            return String(getStatus(order.Actions)).toLowerCase() === "pending";
        });
        setFilteredOrders(ords);
      } else if (activeTab === 2) {
        let ords = orders.filter((order: orderType) => {
          if (order.hasOwnProperty("Actions"))
            return (
              String(getStatus(order.Actions)).toLowerCase() === "complete"
            );
        });

        setFilteredOrders(ords);
      } else if (activeTab === 3) {
        let ords = orders.filter((order: orderType) => {
          if (order.hasOwnProperty("Actions"))
            return (
              String(getStatus(order.Actions)).toLowerCase() === "cancelled"
            );
        });

        setFilteredOrders(ords);
      } else {
        setFilteredOrders(orders);
      }
    }
  }, [activeTab]);

  function getUserAvatar(id: String) {
    let obj = null;

    if (customers)
      obj = customers.filter((customer: any) => customer.userId === id)[0];
    if (!obj || !obj.hasOwnProperty("imageUrl") || obj.imageUrl === "default")
      return "https://bit.ly/3A6xFzI";

    return obj.imageUrl;
  }

  function searchByCode(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.value) {
      let result = filteredOrders.filter((order: any) =>
        String(order.order_code)
          .toLowerCase()
          .includes(String(e.target.value).toLowerCase())
      );
      setFilteredOrders(result);
    } else {
      setFilteredOrders(orders);
    }
  }
  function searchByName(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.value) {
      let result = filteredOrders.filter((order: any) =>
        String(order.userName)
          .toLowerCase()
          .includes(String(e.target.value).toLowerCase())
      );
      setFilteredOrders(result);
    } else {
      setFilteredOrders(orders);
    }
  }

  console.log(filteredOrders[0]);

  return (
    <div className="bg-white p-4 rounded-3 mb-4">
      <h4>Orders</h4>
      {orderInView.data && (
        <OrderDetails
          orderInView={orderInView}
          setOrderInView={setOrderInView}
        />
      )}

      <div className={styles.tab_container}>
        <div className={styles.tabs_wrapper}>
          {" "}
          {["All", "Pending", "Complete", "Cancelled"].map((item, index) => (
            <div
              key={index}
              onClick={() => setActiveTab(index)}
              className={clsx(
                "small",
                styles.tab,
                activeTab === index && styles.tab_active
              )}
            >
              {item}
            </div>
          ))}
        </div>

        <div className="d-flex small">
          <div className="input-group input-group-sm mb-2">
            {" "}
            <input
              type="search"
              className="form-control me-4"
              placeholder="Search by code"
              onChange={searchByCode}
            />
            <input
              type="search"
              className="form-control"
              placeholder="Search by name"
              onChange={searchByName}
            />
          </div>
        </div>
      </div>
      {filteredOrders ? (
        <div className="mt-3">
          <table className="table table-striped small">
            <thead>
              <tr className="fw-bold" style={{ textTransform: "capitalize" }}>
                {[
                  "#",
                  "order code",
                  "Created",
                  "Customer",
                  "Delivery",
                  "Total",
                  "paid",
                  "Method",
                  "Actions",
                ].map((title, index) => (
                  <td key={index}>{title}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredOrders
                .filter((order: any) => order.date != null)
                .sort(
                  (a: any, b: any) =>
                    stringToTimestamp(b?.date) - stringToTimestamp(a?.date)
                )
                .map((item: any, index: number) => (
                  <tr
                    key={item.orderId}
                    style={{
                      textTransform: "capitalize",
                      marginBottom: "10px",
                      backgroundColor: "bg-light",
                      borderRadius: "10px",

                      border: "10px solid #fff",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{item.order_code}</td>
                    <td className="small">{item.date}</td>
                    <td>
                      <div className={styles.user_wrapper}>
                        <div>
                          <img
                            src={getUserAvatar(item.userId)}
                            className={styles.table_avatar}
                            width="27"
                            height="27"
                            alt="avatar"
                          />{" "}
                        </div>

                        {item.userName}
                      </div>
                    </td>
                    <td>{getStatus(item?.Actions)}</td>
                    <td>{item.totalFee}</td>
                    <td>
                      {item.payment_method === "online" ? (
                        (item?.Transaction &&
                          String(
                            item?.Transaction?.transactionStatus
                          ).toLowerCase() === "succeeded") ||
                        (item?.Payment &&
                          String(item?.Payment?.status).toLowerCase() ===
                            "accepted") ? (
                          <span className="text-success">paid</span>
                        ) : (
                          "not paid"
                        )
                      ) : (
                        " N/A"
                      )}
                    </td>
                    <td>
                      {item.payment_method === "offline"
                        ? "cash on delivery"
                        : item.payment_method}
                    </td>
                    <td>
                      <div className="d-flex align-items-cente justify-content-around">
                        <Eye
                          size="15"
                          color="#6eb140"
                          className={styles.action_button}
                          onClick={() =>
                            setOrderInView({ open: true, data: item })
                          }
                        />
                        <Trash
                          size="15"
                          color="#FF0000"
                          className={styles.action_button}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-column my-5">
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden ">Loading...</span>
          </div>
          <div className="small text-muted mt-3">Loading! Please wait</div>
        </div>
      )}
    </div>
  );
};

export default Orders;
