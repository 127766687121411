import { useContext, useEffect } from "react";
import { useNavigate, redirect,Navigate } from "react-router-dom";
import { UserContext } from "../utils/context";
import { auth } from "../utils/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

type childrenType = {
  children:any;
};
const AdminRoutes =  ({ children }: childrenType) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
 useEffect(()=> {
      onAuthStateChanged(auth, (user) => {
        setCurrentUser(user);
      });
 },[])

  if (!currentUser)
   return  <Navigate to="auth/login" />
else
  return children;
};


export default AdminRoutes;