import { useContext, useEffect, useRef } from "react";
import {
  CustomersContext,
  OrdersContext,
  GroceriesContext,
  CategoriesContext,
} from "../../utils/context";
import {
  getCustomers,
  getOrders,
  fetchGroceries,
  getProductCategories,
} from "../../utils/databaseHelper";

import {
  ArrowDown2,
  Grid1,
  Grid2,
  Logout,
  Message,
  Money,
  Money2,
  Setting2,
} from "iconsax-react";
import { NavLink } from "react-router-dom";
import { logUserOut } from "../../utils/AuthHandler";

const Nav: React.FC = () => {
  const { orders, setOrders } = useContext(OrdersContext);
  const { customers, setCustomers } = useContext(CustomersContext);
  const { groceries, setGroceries } = useContext(GroceriesContext);
  const { categories, setCategories} = useContext(CategoriesContext);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (orders === null) getOrders(setOrders);
    if (customers === null) getCustomers(setCustomers);
    if (groceries === null) fetchGroceries(setGroceries);

    if(categories === null) getProductCategories(setCategories);

    document.addEventListener("click", (e) => {
      if (e.target === imgRef.current) {
        document
          .querySelector<HTMLDivElement | any>(".dropdown")
          .classList.add("active");
      } else {
        document
          .querySelector<HTMLDivElement | any>(".dropdown")
          .classList.remove("active");
      }
    });
  }, []);

  let size = 15;
  return (
    <nav className="">
      <div className="container py-3 position-relative">
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div className="small">Admin</div>
          <div className="adm_ posittion-relative">
            <img
              src="https://bit.ly/3pnoLeR"
              width="40"
              height="40"
              ref={imgRef}
              alt="default picture"
              className="rounded-circle"
            />
            <ArrowDown2 size="12" />
            <div className="dropdown p-3 rounded-2  ">
              <div className="d-flex align-items-start gap-3 border-bottom pb-2">
                <img
                  src="https://bit.ly/3pnoLeR"
                  width="40"
                  height="40"
                  alt="default picture"
                  className="rounded-circle"
                />
                <div>
                  <div>Admin</div>
                  <div className="small text-muted">
                    <span className="small">admin@teddyfreshmarket.com</span>
                  </div>
                </div>
              </div>
              <ul className="adm_ul pt-2">
                {[
                  {
                    icon: <Grid2 size={size} />,
                    title: "dashboard",
                    link: "/",
                  },
                  {
                    icon: <Money2 size={size} />,
                    title: "orders",
                    link: "/orders",
                  },
                  {
                    icon: <Message size={size} />,
                    title: "messages",
                    link: "/messages",
                  },
                  {
                    icon: <Setting2 size={size} />,
                    title: "settings",
                    link: "/settings",
                  },
                ].map((item: any) => (
                  <li key={item.title} className="py-2 small text-capitalize">
                    <NavLink
                      className="d-flex align-items-center gap-2 text-muted"
                      to={item.link}
                      end
                    >
                      <span className="small">{item.icon}</span>
                      <span className="small">{item.title}</span>
                    </NavLink>
                  </li>
                ))}
                <div className="border-bottom mt-2" />
                <li
                  className="d-flex align-items-center gap-2 small pt-3 text-danger"
                  onClick={logUserOut}
                >
                  <Logout size={size} />
                  <span className="small">Logout</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
