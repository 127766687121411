import React from 'react'
import styles from '../../styles/page.styles.module.css';
import clsx from 'clsx';


type groceryType = {
 groceries:{
    [key: string]: any,
    itemName: string,
    imageUrl:string,
    itemId:string,
 }[]
}
const TopSellingItems: React.FC<groceryType> = ({groceries}) => {
  return (
    <div className="rounded-3 bg-white px-4 py-4">
      <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div className={clsx(styles.card_label, )}>
          Top Selling Items
        </div>
        <button className="btn btn-sm text-capitalize"><span className="small">View all</span></button>
      </div>

      <div className="">
        {groceries.slice(0, 8).map((grocery, index) => (
          <div
            key={index}
            className="d-flex align-items-center border-bottom py-2"
          >
            <div className="me-2">
              <img src={grocery.imageUrl} width="40" />
            </div>
            <div className="flex-1">
              <div className={styles.top_sell_}>{grocery.itemName}</div>
              <div className={clsx(styles.top_sell_cat, "text-muted")}>
                {grocery.category}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TopSellingItems