import React, { useContext } from "react";
import styles from "../../styles/page.styles.module.css";
import clsx from "clsx";

type StatProps = {
  text: string;
  icon: any;
  bg: string;
  count: number;
};

const StatisticsGrid: React.FC<StatProps> = ({ text, icon, bg, count }) => {
  return (
    <div
      className={clsx(
        styles.min_card,
        "d-flex align-items-center rounded-3 p-2 px-3 bg-white w-100"
      )}
    >
      <div
        style={{ background: bg, width: "35px", height: "35px" }}
        className="rounded-circle p-2 d-flex align-items-center justify-content-center me-3"
      >
        {icon}
      </div>

      <div className="">
        <div className={clsx(styles.stat_head,"text-muted")}>{text}</div>
        <div className={clsx(styles.stat_num, "")}>{count}</div>
      </div>
    </div>
  );
};

export default StatisticsGrid;
