import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";

import { Routes, Route, NavLink, RouteProps } from "react-router-dom";
import BlogsList from "../pages/BlogsList";
import Nav from "../components/nav/Nav";
import styles from "./styles/layout.style.module.css";
import logo from "../assets/logo/teddy-logo.png";
import {
  ElementEqual,
  Shop,
  Cup,
  Messages3,
  DollarSquare,
  Setting2,
  People,
  Profile2User,
  MessageAdd1,
  Book,
} from "iconsax-react";
import Customers from "../pages/Customers";
import Orders from "../pages/Orders";
import Products from "../pages/Products";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users";
import Messages from "../pages/Messages";
import Promotions from "../pages/Promotions";
import Settings from "../pages/Settings";
import AddProduct from "../pages/AddProduct";
import EditCustomer from "../pages/EditCustomer";
import EditUser from "../pages/EditUser";
import Categories from "../pages/Categories";

const MainLayout: React.FC = () => {
  const { collapseSidebar } = useProSidebar();
  const size = 15;
  const iconVariant = "Linear";

  return (
    <>
      <div className={styles.main_wrapper}>
        <div className={styles.sidebar_wrapper}>
          {/* <button onClick={() => collapseSidebar()}>collapse</button> */}

          <Sidebar
            width="300px"
            rootStyles={{
              height: "100%",
              fontSize: "15px",
              position: "relative",
            }}
          >
            <div className="p-4 ">
              <img src={logo} width="70px" alt='logo'/>
            </div>
            <Menu className="px-4">
              <MenuItem
                component={<NavLink to="/" end />}
                icon={<ElementEqual size={size} variant={iconVariant} />}
              >
                Dashboard
              </MenuItem>
              <SubMenu
                icon={<Shop size={size} variant={iconVariant} />}
                label="My shop"
                defaultOpen={true}
                open={true}
              >
                <MenuItem
                  component={<NavLink to="/groceries" />}
                  icon={<Cup size={size} variant={iconVariant} />}
                >
                  Groceries
                </MenuItem>
                <MenuItem
                  component={<NavLink to="/categories" />}
                  icon={<Cup size={size} variant={iconVariant} />}
                >
                  Categories
                </MenuItem>
                <MenuItem
                  component={<NavLink to="/orders" />}
                  icon={<DollarSquare size={size} variant={iconVariant} />}
                >
                  Orders
                </MenuItem>

                <MenuItem
                  component={<NavLink to="/customers" />}
                  icon={<People size={size} variant={iconVariant} />}
                >
                  Customers
                </MenuItem>
              </SubMenu>
              <MenuItem icon={<Messages3 size={size} variant={iconVariant} />}>
                Messages
              </MenuItem>
              <MenuItem
                component={<NavLink to="/blogs" />}
                icon={<MessageAdd1 size={size} variant={iconVariant} />}
              >
                Blogs
              </MenuItem>
              <MenuItem icon={<Cup size={size} variant={iconVariant} />}>
                Promotions
              </MenuItem>
              <MenuItem
                component={<NavLink to="/users" />}
                icon={<Profile2User size={size} variant={iconVariant} />}
              >
                Users
              </MenuItem>
              <MenuItem icon={<Setting2 size={size} variant={iconVariant} />}>
                Settings
              </MenuItem>
            </Menu>
          </Sidebar>
        </div>
        <div className={styles.main_container}>
          <Nav />
          <main className="container px-4">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/blogs" element={<BlogsList />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customer/:id" element={<EditCustomer />} />
              <Route path="/users" element={<Users />} />
              <Route path="/user/:id" element={<EditUser />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/groceries" element={<Products />} />
              <Route path="/categories" element={<Categories/>}/>
              <Route path="/messages" element={<Messages />} />
              <Route path="/promotions" element={<Promotions />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/grocery/add-edit" element={<AddProduct />} />
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
