import { createContext } from 'react';
import { OrderContextType, UserContextType ,CustomersContextType, GroceriesContextType} from '../../App';

export const OrdersContext = createContext<OrderContextType | null | any>({
  orders: null,
  setOrders: () => {},
});

export const GroceriesContext = createContext<GroceriesContextType | null | any>({
groceries: null, setGroceries:() => {},
});


export const CustomersContext = createContext<CustomersContextType | undefined | any>({
    customers: undefined, setCustomers: () => {},
});

export const BlogsContext = createContext(null);

export const MessagesContext = createContext(null);

export const UserContext = createContext<UserContextType | null | any>({
  currentUser: Object,
  setCurrentUser: () => {},
});


export type CategoriesContextType = {
  categories:any[] | null;
  setCategories: React.Dispatch<React.SetStateAction<object>>;
};

export const CategoriesContext = createContext<CategoriesContextType>({
  categories: [] || null,
  setCategories: () => {},
});