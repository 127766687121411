import React from "react";

interface FieldProps {
  type: string;
  id?: string;
  value?: string | number,
  placeHolder?: string | "";
  name: string;
  label:string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  required?: boolean
}

const Input: React.FC<FieldProps> = ({
  type = "text",
  id,
  onChange,
  placeHolder = "",
  name,
  value,
  label,
  disabled = false,
  required =false
}) => {
  return (
    <div className="form-group">
      <label htmlFor={id} className="form-label text-capitalize">{label}</label>
      <input
        type={type}
        placeholder={placeHolder}
        name={name}
        value={value}
        className="form-control"
        onChange={onChange}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

export default Input;
