import clsx from "clsx";
import React, { useContext, useState } from "react";
import styles from "./styles/page.styles.module.css";
import {
  addProductCategory,
  updateProductCategories,
} from "../utils/databaseHelper";
import { CategoriesContext } from "../utils/context";

type formDataType = {
  imageUrl: string;
  name: string;
  description: string;
  id: string | null;
  arrangement: number | null;
};

const Categories = () => {
  let defaultData = {
    imageUrl: "",
    name: "",
    description: "",
    id: null,
    arrangement: null,
  };
  const [uploadStatus, setUploadStatus] = useState(false);

  const [tempImageUrl, setTempImageUrl] = useState<string | null>();
  const [imageFile, setImageFile] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [formData, setFormData] = useState<formDataType>({
    ...defaultData,
  });
  const { categories } = useContext(CategoriesContext);

  const onChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "arrangement"
          ? Number(e.target.value)
          : e.target.value,
    });
  };
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setUploadStatus(true);
    if (isEditing) {
      await updateProductCategories(formData, imageFile);
    } else {
      await addProductCategory(formData, imageFile);
      setFormData(defaultData);
      setTempImageUrl(null);
    }
    setUploadStatus(false);
  };

  const onImageChange = (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      setTempImageUrl(imageUrl);
      setImageFile(file);
    }
  };

  return (
    <div className="bg-white py-5 px-3">
      <div className="row">
        <div className="col-sm-12 col-md-6">
          {categories ? (
            <table className="table table-sm table-striped table-bordered">
              <thead>
                <tr className="small">
                  <td>Image</td>
                  <td className="text-sm">Name</td>
                  <td className="text-sm">Arrangement</td>
                  <td></td>
                </tr>
              </thead>
              <tbody className="small text-capitalize">
                {categories
                  ?.sort((a, b) => a.arrangement - b.arrangement)
                  ?.map((category: any) => (
                    <tr key={category.id} className="small">
                      <td>
                        <img
                          src={category.imageUrl}
                          alt={category.name}
                          width={50}
                        />
                      </td>
                      <td>{category.name}</td>
                      <td>{category.arrangement}</td>
                      <td>
                        <button
                          className="btn btn-sm small"
                          type="button"
                          onClick={() => {
                            setIsEditing(true);
                            setFormData({ ...category });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <>Loading...</>
          )}
        </div>
        <div className="col-sm-12 col-md-6">
          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <div className="border rounded-1 px-4 py-3">
                <div className="row">
                  <div className="col col-md-4 upl">
                    <label
                      htmlFor="image_file"
                      className={clsx(
                        styles.image_upload,
                        " d-flex align-items-center justify-content-center flex-column px-3 py-5 rounded-3"
                      )}
                    >
                      <span
                        className={clsx(
                          styles.upload_icon,
                          "material-symbols-outlined mb-2"
                        )}
                      >
                        imagesmode
                      </span>
                      {/* <Image size="32" color="#555555" /> */}
                      <span className={styles.upload_label}>
                        Click to upload
                      </span>
                    </label>
                    <input
                      type="file"
                      className="hide_field"
                      name="image_file"
                      id="image_file"
                      accept="image/*"
                      onChange={onImageChange}
                      disabled={uploadStatus}
                    />
                  </div>
                  <div className="col col-md-6">
                    <div
                      className={clsx(
                        styles.upload_img,
                        "d-flex align-items-center justify-content-center flex-column  px-3 py-4 rounded-3"
                      )}
                    >
                      <img
                        alt="category"
                        src={
                          tempImageUrl ||
                          formData.imageUrl ||
                          "https://bit.ly/2ut62it"
                        }
                        width="103"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="category" className="form-label small text-muted">
                Category Name
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                id="name"
                value={formData.name}
                placeholder="Enter category name"
                onChange={onChange}
                disabled={uploadStatus}
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="description"
                className="form-label small text-muted"
              >
                Description
              </label>
              <textarea
                rows={5}
                name="description"
                className="form-control"
                value={formData.description}
                id="category"
                placeholder="Enter description"
                onChange={onChange}
                disabled={uploadStatus}
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label small text-muted">Arrangement</label>
              <select
                name="arrangement"
                className="form-select form-selet-sm"
                required
                onChange={onChange}
                id="arrangement"
                disabled={uploadStatus}
              >
                <option value="">Select</option>
                {Array.from([...new Array(10)]).map((_, index) => (
                  <option
                    key={index}
                    value={index}
                    selected={index === formData.arrangement}
                  >
                    {index}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-5">
              <button
                type="submit"
                disabled={uploadStatus}
                className="btn btn-primary btn-sm"
              >
                {!uploadStatus ? "Add Category" : "Adding category"}
              </button>
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => {
                  setIsEditing(false);
                  setFormData(defaultData);
                  setTempImageUrl(null);
                }}
              >
                Clear
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Categories;
