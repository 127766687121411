import React, { useContext, useEffect, useState } from "react";
import { GroceriesContext } from "../../utils/context";
import { formatCurrency } from "../../utils/functions";

const PurcahaseList = ({ cartItems, orderCode }: any) => {
  //console.log(cartItems)
  const { groceries } = useContext(GroceriesContext);

  const [purchaseList, setPurchaseList] = useState<any[]>();

  useEffect(() => {
    let list = computeMargins();

    setPurchaseList(list);
  }, [cartItems]);

  const computeMargins = (): any[] => {
    const items: any[] = [];
    let item: object | any;
    for (item of Object.values(cartItems)) {
      let grocery = groceries.filter(
        (grocery: any) => grocery.itemId === item?.itemId
      )[0];
      let percent = grocery?.purchaseMargin || 1;
      let purchasePrice =
        (item.itemPrice - (percent / 100) * item.itemPrice) * item.itemCount;

      items.push({ name: grocery?.itemName, ...item, purchasePrice });
    }

    return items;
  };

  return (
    <div className="px-3">
      <h3 className="mb-3">Purchase List</h3>
      <div className="small">
        <span className="" style={{ fontWeight: "600" }}>
          Order code:
        </span>{" "}
        {orderCode}
      </div>
      {purchaseList && (
        <table className="table table-striped small">
          <thead className="bg-secondary text-white">
            <tr className="text-capitalize">
              {["name", "qty", "puchasing price"].map((item, index) => (
                <td key={index}>{item}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {" "}
            {purchaseList.map((item: any, index:number) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>
                  <span className="small text-muted">x</span> {item.itemCount}
                </td>
                <td>{formatCurrency(item.purchasePrice)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PurcahaseList;
