import React, { useContext, useEffect, useRef, useState } from "react";
import { GroceriesContext } from "../utils/context";
import styles from "./styles/page.styles.module.css";
import clsx from "clsx";
import { currencyFormmatter } from "../utils/utils";
import { Link } from "react-router-dom";
import { ArrowRight, Category2, Edit, Printer, TextalignRight } from "iconsax-react";
import ReactToPrint from "react-to-print";
import logo from "../assets/logo/teddy-logo.png";

const Products = () => {
  const { groceries } = useContext(GroceriesContext);
  const [filteredList, setFilteredList] = useState([]);
     const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (groceries) setFilteredList(groceries);
  }, [groceries]);

  function searchByName(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.value !== "") {
      let data = filteredList.filter((grocery: any) =>
        grocery.itemName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredList(data);
    } else {
      setFilteredList(groceries);
    }
  }
  function filterByCategory(e: React.ChangeEvent<HTMLSelectElement>): void {
    if (e.target.value.toLowerCase() !== "any") {
      let data = groceries.filter(
        (item: any) =>
          item.category.toLowerCase() === e.target.value.toLowerCase()
      );
      setFilteredList(data);
    } else {
      setFilteredList(groceries);
    }
  }
  function filterByAvailable(e: React.ChangeEvent<HTMLSelectElement>): void {
    if (e.target.value.toLowerCase() !== "any") {
      let data = filteredList.filter(
        (item: any) =>
          item.availability.toLowerCase() === e.target.value.toLowerCase()
      );
      setFilteredList(data);
    } else {
      setFilteredList(groceries);
    }
  }

  return (
    <>
      <div className="bg-white px-4 rounded-3">
        <div className="d-flex align-items-center justify-content-between pt-4">
          <h4>Groceries List</h4>
          <Link
            to="/grocery/add-edit"
            state={{ grocery: null }}
            className="btn btn-sm btn-outline-primary text-capitalize"
          >
            <span className="small">Add new</span>{" "}
            <ArrowRight size="18" color="#6eb140" />
          </Link>
        </div>

        <div>
          <div className="row row-3 gap-4 my-3 g-filters p-4">
            <div className="col">
              <label className="form-label small" htmlFor="name">
                Search by name
              </label>
              <input
                type="search"
                id="name"
                onChange={searchByName}
                className="form-control"
                placeholder="Enter item name"
                disabled={groceries === null}
              />
            </div>

            <div className="col">
              <label className="form-label small" htmlFor="name">
                Filter by category
              </label>
              <select
                className="form-select text-capitalize text-muted"
                style={{ fontSize: "15px" }}
                disabled={groceries === null}
                onChange={filterByCategory}
              >
                <option>Any</option>
                {[
                  "vegetables",
                  "fruits",
                  "meats & fish",
                  "offers",
                  "ryans garden",
                  "cooking essentials",
                  "more groceries",
                ].map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <label className="form-label small" htmlFor="name">
                Filter by availability
              </label>
              <select
                className="form-select text-capitalize text-muted"
                style={{ fontSize: "15px" }}
                disabled={groceries === null}
                onChange={filterByAvailable}
              >
                <option>Any</option>
                {["in stock", "out of stock"].map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {groceries && (
            <>
              {filteredList.length ? (
                <>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <button
                      className="btn btn-sm btn-outline-secondary float-left"
                      data-bs-toggle="modal"
                      data-bs-target="#catalogue"
                    >
                      <span className="small">View Catalogue</span>
                    </button>
                    <div className="d-flex align-items-center justify-content-end gap-4 ">
                      <Category2 size="18" color="#555555" variant="Bold" />
                      <TextalignRight
                        size="18"
                        color="#555555"
                        variant="Bold"
                      />
                    </div>
                  </div>

                  <div
                    className={clsx(
                      styles.grocery_wrapper,
                      "row row-cols-2 row-cols-lg-5 "
                    )}
                  >
                    {filteredList?.map((grocery: any) => (
                      <>
                        <div
                          className={clsx(
                            styles.grocery_card,
                            "col  p-3 text-center border radius-3 position-relative"
                          )}
                          key={grocery.itemId}
                        >
                          <img
                          alt="grocery"
                            src={grocery.imageUrl}
                            width="70"
                            className="my-2"
                          />
                          <h6>{grocery.itemName}</h6>
                          <div className="small">{grocery.category}</div>
                          <div className="small">
                            {currencyFormmatter.format(grocery.itemPrice)}
                          </div>
                          <div className="small">{grocery.availability}</div>
                          <div className={clsx(styles.prod_hover)}>
                            <Link
                              to="/grocery/add-edit"
                              state={{ grocery }}
                              className={styles.prod_edit_button}
                            >
                              {" "}
                              <Edit size="20" color="#fff" />
                            </Link>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <div className="d-flex align-items-center justify-content-center flex-column my-5">
                  <div className="small text-muted mt-3">No record found</div>
                </div>
              )}
            </>
          )}
          {!groceries && (
            <div className="d-flex align-items-center justify-content-center flex-column my-5">
              <div className="spinner-border text-secondary" role="status">
                <span className="visually-hidden ">Loading...</span>
              </div>
              <div className="small text-muted mt-3">Loading! Please wait</div>
            </div>
          )}
        </div>
      </div>
      {/*Backdrop */}

      <div
        className="modal fade"
        id="catalogue"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fs-5" id="staticBackdropLabel">
                Product Catalogue
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" ref={printRef}>
              {groceries ? (
                <Catelogue groceries={filteredList} />
              ) : (
                "Loading groceries! Please wait.."
              )}
            </div>
            <div className="modal-footer">
              <ReactToPrint
                pageStyle="padding-top:20px"
                documentTitle="Product catalogue"
                trigger={() => (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                  >
                    <Printer /> Print
                  </button>
                )}
                content={() => printRef.current}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

const Catelogue = ({ groceries }: any) => {
  return (
    <>
      <div className="text-center">
        <img src={logo} width="80" className="my-3" alt="logo"/>
      </div>
      <table className="table">
        <thead className="bg-dark-subtle">
          <tr className=" fw-bold text-capitalize ">
            {["Product", "per", "Price"].map((item: string, index: number) => (
              <td key={index}>{item}</td>
            ))}
          </tr>
        </thead>
        <tbody className="">
          {[
            groceries.map((grocery: any) => (
              <tr className=" text-capitalize" key={grocery.in}>
                <td>
                  <img
                    src={grocery.imageUrl}
                    width="30"
                    height="30"
                    className="me-2"
                    alt="grocery"
                  />
                  {grocery?.itemName}
                </td>
                <td>{grocery?.unit}</td>
                <td>{grocery?.itemPrice}</td>
              </tr>
            )),
          ]}
        </tbody>
      </table>
    </>
  );
};
